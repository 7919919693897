import React, { useContext, useEffect } from "react";
import { Box, Embed } from "theme-ui";
import { AuthContext } from "Authentication";
import { axpItineraryUrl } from "utils/urls";
import { navigate, Script } from "gatsby";
import Layout from "components/Layout";
import { LoadingIndicator } from "components";
import SEO from "components/SEO";

export default function Itinerary({}) {
  const { isSignedIn, profile } = useContext(AuthContext);

  useEffect(() => {
    if (!isSignedIn) navigate("/sign-in");
  }, [isSignedIn]);

  return (
    <Layout page="Itinerary">
      {isSignedIn ? (
        <Box className="fullSize">
          <Embed
            src={axpItineraryUrl(profile.id)}
            name="Itinerary"
            sx={{ height: "100vh" }}
          />
        </Box>
      ) : (
        <LoadingIndicator />
      )}
    </Layout>
  );
}

export const Head = () => (
  <SEO title="Itinerary">
    <Script>
      {`if (!localStorage.getItem("itinerary_embed")) {
        localStorage.setItem("itinerary_embed", true);
      }`}
    </Script>
  </SEO>
);
